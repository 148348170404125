// Gatsby requirements
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { useStoryblokState } from "gatsby-source-storyblok"

// Components
import Layout from '../components/layout/layout'
import Seo from '../components/seo'

// Storyblok Components
import StoryblokComponents from 'utils/StoryblokComponents'


const PageTemplate = ({ data }) => {

    const [previewStory, setPreviewStory] = useState(null);

    let story = JSON.parse(data.storyblokEntry.content);

    const livePreview = useStoryblokState(data.site_configuration);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            setPreviewStory(null);
        }
    }, [livePreview]);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            const url = `https://api.storyblok.com/v2/cdn/stories/site-configuration?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
        
            const fetchData = async () => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();
                    setPreviewStory(json.story);
                } catch (error) {
                    console.log("error", error);
                }
            };
        
            fetchData();
        }
    }, []);

    const previewData = previewStory ? previewStory.content : livePreview.content;

    return (
        <Layout
            en={data.storyblokEntry?.full_slug?.startsWith('en/')}
            alternateSlug={data.storyblokEntry?.content?.translation_of?.cached_url === 'home' ? '/' : `/${data.storyblokEntry?.content?.translation_of?.cached_url}`}
        >

            <div>

                <Seo
                    title={data.storyblokEntry?.field_meta_title_string}
                    description={data.storyblokEntry?.field_meta_description_string}
                    thumbnail={data.storyblokEntry.content?.meta_image?.filename}
                    type="website"
                    url={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry?.full_slug}`}
                    noindex={true}
                    en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                    alternateSlug={data.storyblokEntry?.content?.translation_of?.cached_url === 'home' ? '/' : `/${data.storyblokEntry?.content?.translation_of?.cached_url}`}
                    previewData={previewData}
                />

                <main id="content" className="page">

                    <StoryblokComponents
                        content={story?.blocks}
                        en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                        currentPageName={data.storyblokEntry?.name}
                        currentPage={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry?.full_slug}`}
                    />
    
                </main>

            </div>

        </Layout>
    )
}

// Page query, the blocks are in "content" as JSON
export const query = graphql`
  query {
    storyblokEntry(full_slug: { eq: "home" }) {
        full_slug
        field_meta_description_string
        field_meta_title_string
        field_noindex_boolean
        name
        content
        id
        uuid
        internalId
    }
    site_configuration: storyblokEntry(field_component: {eq: "Site Configuration"}) {
        content
        id
        uuid
        internalId
    }
  }
`

export default PageTemplate
